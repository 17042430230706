<template>
	<div>
  	<Breadcrumbs :items="crumbs" />
    <div>
      <div class="fullscreen-bg videoWrapper" v-if="$vuetify.breakpoint.mdAndUp">
      <video autoplay loop autobuffer muted playsinline class="fullscreen-bg-vid">
        <source src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/0videosOfficial%2FBevLicense.mp4?alt=media&token=74199065-1d81-4154-ba4b-f81b15ee5173" type="video/mp4">
      </video>
      <div class="overlay">
        <v-container>
          <div class="display-3">
          <span class="thin">Beverage</span>
        </div><br />
        <div class="display-4">
          <span class="thick">Licensing</span>
        </div>
        
        </v-container>
      </div>
    </div>

    <div class="fullscreen-bg videoWrapper" v-if="$vuetify.breakpoint.smAndDown">
      <video autoplay loop autobuffer muted playsinline class="fullscreen-bg-vid">
        <source src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/0videosOfficial%2FBevLicenseSmall.mp4?alt=media&token=83c7fe96-96f0-44a5-a541-ea03e7644478" type="video/mp4">
      </video>
      <div class="overlay">
        <v-container>
          <div class="display-3">
          <span class="thin">Beverage</span>
        </div><br />
        <div class="display-4">
          <span class="thick">Licensing</span>
        </div>
        
        </v-container>
      </div>
    </div>



    
    </div>
    <div class="white">
      <v-container pt-5 pb-5>
        <v-layout row>
          <v-flex>
            <div class="headline">Making the ordinary extraordinary</div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <div class="grey lighten-4">
      <v-container pt-5 pb-5>
        <v-layout row>
          <v-flex>
            <div class="title mb-1">Provide collaborative experiences</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Perfect for spirits and wine producers</div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <div class="white">
    <v-container grid-list-xs fluid pa-0>
      <v-layout wrap>
        <v-flex
          xs4
        >
          <v-card flat tile>
            <v-img
              src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/Holiday%20copy-min.jpg?alt=media&token=070e9224-2ac5-49b4-bf7a-2b451d0df858"
              :aspect-ratio="1"
            ></v-img>
          </v-card>
        </v-flex>
        <v-flex
          xs4
        >
          <v-card flat tile>
            <v-img
              src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/effen3.png?alt=media&token=919de26b-d7f4-49f5-a904-59d84d69e01d"
              :aspect-ratio="1"
            ></v-img>
          </v-card>
        </v-flex>
        <v-flex
          xs4
        >
          <v-card flat tile>
            <v-img
              src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/Medea%20-%20press%20release%20image%20without%20logo%20copy.jpg?alt=media&token=2854fc15-316e-4a38-ba04-107b52b69378"
              :aspect-ratio="1"
            ></v-img>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    </div>
	</div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
	data() {
    return {
      name: this.$route.name,
      crumbs: [
        {
          text: 'Home',
          disabled: false,
          exact: true,
          to: '/'
        },
        {
          text: 'Industries',
          disabled: false,
          exact: true,
          to: '/industries'
        },
        {
          text: 'Beverage Licensing',
          disabled: true,
          to: ''
        }
      ],
    }
 	},
	components: {
    Nav,
    Breadcrumbs,
    Footer
  },
}
</script>